var testimonialSwiper = false;

;(function ($) {
  $(window).on('load', function () {

    // Submenu toggles for mob
    initHeader()

    // Output the dynamic months
    outputMonth()

    initModal()

    // Components loading animations
    $('.view-animation').viewportChecker({
      classToAdd: 'animated',
      offset: 20
    })

    // Inline Video Funcionality
    $(document).on('click', '.inline-video-trigger', function () {

      if($(this).closest('.section-videos').length && testimonialSwiper !== false){
          var slideIndex = $(this).closest('.swiper-slide').attr('aria-label');
          slideIndex = slideIndex.split(' / ')[0] - 1;
          testimonialSwiper.autoplay.stop();
          testimonialSwiper.slideTo(slideIndex, 600, false);
      }


      if ($(this).data('video-id')) {
        if ($(this).hasClass('vimeo')) {
          var iframeHTML =
            '<iframe src="https://player.vimeo.com/video/' +
            $(this).attr('data-video-id') +
            '?title=0&byline=0&portrait=0?&autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>'
        } else {
          var iframeHTML =
            '<iframe src="https://www.youtube.com/embed/' +
            $(this).attr('data-video-id') +
            '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
        }
        $(this)
          .parents('.vsl-box, .swiper-slide, .circular-vsl')
          .addClass('playing')
        $(this)
          .parents('.vsl-box, .swiper-slide, .circular-vsl')
          .find('.hide-on-play')
          .fadeOut()
        $(this)
          .parents('.vsl-box, .swiper-slide, .circular-vsl')
          .find('iframe')
          .remove()
        $(this)
          .parent('.video-preview-container')
          .append(iframeHTML)
      } else {
        console.error('no video ID provided.')
      }
    })

    // Phone Concatenation Script For Tracking
    setTimeout(function () {
      $('.phone-text em').each(function () {
        var unsliced = $(this).text()
        var sliced = unsliced.slice(0, -2) + '...'
        $(this).text(sliced)
        var linked = 'tel:' + unsliced.replace(/\s/g, '')
        $(this).click(function () {
          if ($(window).width() < 1000) {
            window.location.href = linked
          } else {
            $(this).text(unsliced)
          }
        })
      })
    }, 3000);


    removeFakePlaceholder();
    $('form input').on('keyup', function(){
    	removeFakePlaceholder();
    });
    function removeFakePlaceholder(){
	    $('form input').each(function(){
	    	if($(this).val() && $('+ [style*="pointer-events: none;"]', this).length){
	    		$('+ [style*="pointer-events: none;"]', this).css('opacity',0);
	    	}else{
	    		$('+ [style*="pointer-events: none;"]', this).css('opacity',1);
	    	}
	    });
	}

    // Modifies the options to include those set within the data-* attributes.
    function getSwiperOptionAttributes (options, swiperElm) {
      var optionsAttributes = swiperElm.dataset

      if (optionsAttributes.hasOwnProperty('slidesperview')) {
        options.slidesPerView = parseInt(optionsAttributes.slidesperview)
      }
      if (optionsAttributes.hasOwnProperty('spacebetween')) {
        options.spaceBetween = parseInt(optionsAttributes.spacebetween)
      }
      if (optionsAttributes.hasOwnProperty('loop')) {
        options.loop = optionsAttributes.loop == 'true'
      }
      if (optionsAttributes.hasOwnProperty('disableautoplay')) {
        options.autoplay = false
      }

      return options
    }

    // get all sliders, we need to loop them due to different settings + nav
    var swipers = document.querySelectorAll(
      '.swiper-container:not(.control):not(.mobile)'
    )

    swipers.forEach(function (el, index) {
      var closestSection = el.closest('section')
      var controls = closestSection.querySelector('.control')
      var testimonialVideos = closestSection.classList.contains(
        'section-videos'
      )
      var testimonials = closestSection.classList.contains(
        'section-testimonials'
      )

      // Slider default settings
      var options = {
        speed: 600,
        loop: true,
        slidesPerView: 1,
        observer: true,
        observerParents: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true
        },
        navigation: {},
        thumbs: {}
      }

      // Slider attribute overrides
      options = getSwiperOptionAttributes(options, el)

      if (testimonialVideos) {
        options.slidesPerView = 'auto';
        options.centeredSlides = true;
        options.pagination = {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        }
        options.freeMode = true;
        options.speed = 8500;
        options.autoplay = {
          delay: 1,
          disableOnInteraction: false,
          pauseOnMouseEnter: false
        };
      }
      if (testimonials) {
        options.slidesPerView = 1
        options.breakpoints = {
          576: {
            slidesPerView: 'auto'
          }
        }
      }

      // init nav
      options.navigation = {
        nextEl: closestSection.querySelector('.swiper-next'),
        prevEl: closestSection.querySelector('.swiper-prev')
      }

      // maybe add gallery controls to the slider
      if (controls) {
        var controlOptions = {
          speed: 600,
          loop: true,
          slidesPerView: 4
        }

        controlOptions = getSwiperOptionAttributes(controlOptions, controls)

        options.thumbs.swiper = new Swiper(controls, controlOptions)
      }

      // init slider
      if (testimonialVideos) {
        testimonialSwiper = new Swiper(el, options);
      }else{
        var swiper = new Swiper(el, options);
      }
      if (testimonials) {
        swiper.snapGrid = swiper.slidesGrid.slice(0)
      }
    })

    // mobile sliders, like logo rows etc
    // need to loop in order to get the slide count
    var mobileSwipers = document.querySelectorAll('.swiper-container.mobile')
    mobileSwipers.forEach(function (el, index) {
      var closestSection = el.closest('section')
      var slideCount = el.querySelectorAll('.swiper-slide').length
      var features = closestSection.classList.contains('section-features')
      var imagecopy = closestSection.classList.contains('section-image-copy')


      var options = {
        speed: 600,
        slidesPerView: 2,
        watchOverflow: true,
        loop: true,
        simulateTouch: false,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        breakpoints: {
          576: {
            slidesPerView: 3
          },
          768: {
            slidesPerView: 4
          },
          992: {
            slidesPerView: slideCount
          }
        }
      }

      if (features) {
        options.slidesPerView = 1
        options.breakpoints = {
          576: {
            slidesPerView: 2
          },
          992: {
            slidesPerView: slideCount
          }
        }
      }
      if( imagecopy ) {
       //options.autoHeight = true;
        options.breakpoints = false;
      }

      // init nav
      options.navigation = {
        nextEl: closestSection.querySelector('.swiper-next'),
        prevEl: closestSection.querySelector('.swiper-prev')
      }

      options = getSwiperOptionAttributes(options, el)

      // init slider
      swiper = new Swiper(el, options)
    })
  });


  // var wpcf7Elm = document.querySelector( '.wpcf7' );
  // if(wpcf7Elm){
  //     wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
  //         if(wpcf7Elm.querySelector('[name="redirect"]')){
  //             var redirect = wpcf7Elm.querySelector('[name="redirect"]').value;
  //             window.location.href = redirect;
  //         }
  //     }, false );
  // }

  // Sticky Header
  $(window).on('scroll load', function () {
    if ($(window).scrollTop() >= 50) {
      $('.site-header').addClass('scrolled')
    } else {
      $('.site-header').removeClass('scrolled')
    }
  })

   if(window.location.href.indexOf('eb=1') > -1){
    $('body').addClass('from-ebook');
    $('.ebook-thankyou').prependTo('body');
}

   var headerOffset = $('header').offset().top;
   if(window.location.href.indexOf('eb=1') == -1){
       headerOffset = 0;
       headerOffset = headerOffset + 20;
   }else{
       $('header').css({ top: $('.ebook-thankyou').outerHeight() });
   }

   $(window).on('resize', function(){
       headerOffset = $('header').offset().top;
       if(window.location.href.indexOf('eb=1') == -1){
           headerOffset = headerOffset + 20;
       }else{
           $('header').css({ top: $('.ebook-thankyou').outerHeight() });
       }
   });
  // Smooth Scroll To Anchor
  $(document).on('click', 'a[href*="#"]', function (event) {
    event.preventDefault()
    var target = $(this).attr('href')

    if ($(target).length) {
      $('html, body').animate(
        {
          scrollTop: $(target).offset().top - $('.site-header').outerHeight()
        },
        1500
      )
    }
  })

  function initModal () {
    var modalSelector = '[href="#modal"], [data-open="modal"]';

    $(document).on('click', modalSelector, function () {
      $('html,body').toggleClass('modal-open')
    })
    $(document).on('click', function (e) {
      if (
        (!$(event.target).closest('.modal-inner').length &&
          !$(event.target).is(modalSelector)) ||
        $(event.target).is('.close')
      ) {
        $('html,body').removeClass('modal-open')
      }
    })
  }
})(jQuery)

function initHeader () {
  var headerItems = document.querySelectorAll('.menu-item-has-children')

  if (!headerItems.length) return

  for (var i = 0; i < headerItems.length; i++) {
    headerItems[i].addEventListener('click', function () {
      this.classList.toggle('active')
    })
  }
}

function outputMonth () {
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  var now = new Date()
  var thisMonth = months[now.getMonth()] // getMonth method returns the month of the date (0-January :: 11-December)
  var output = document.getElementsByClassName('output')

  if (output.length) {
    for (var i = 0; i < output.length; i++) {
      output[i].innerHTML = thisMonth
    }
  }
}

